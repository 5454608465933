@font-face {
  font-family: "Brother-1816";
  src: url("./assets/fonts/Brother 1816/TipoType - Brother-1816-Regular.otf") format('opentype');
}
@font-face {
  font-family: "Brother-1816";
  src: url("./assets/fonts/Brother 1816/TipoType - Brother-1816-Regular-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Brother-1816";
  src: url("./assets/fonts/Brother 1816/TipoType - Brother-1816-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "Brother-1816";
  src: url("./assets/fonts/Brother 1816/TipoType - Brother-1816-Book.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Brother-1816";
  src: url("./assets/fonts/Brother 1816/TipoType - Brother-1816-Black.otf") format("opentype");
  font-weight: 900;
}
@font-face {
  font-family: "BellMTStd";
  src: url("./assets/fonts/Bell/BellMTStd-Regular.otf") format('opentype');
}
@font-face {
  font-family: "BellMTStd";
  src: url("./assets/fonts/Bell/BellMTStd-Bold.otf") format('opentype');
  font-weight: bold;
}

html {
  width: 100%;
  height: 100%;
  font-family: Brother-1816, Arial, sans-serif;
  font-size: 16px;
}

body {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

body.open-dialog {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none;
}

.dimmed {
  opacity: 0.25;
  cursor: initial !important;
}

.spinner {
  margin: 100px auto 0;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner#inContinueButton {
  margin: 0 auto;
}

.spinner > div {
  background-color: #aaa;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner#inContinueButton > div {
  background-color: #e6e6e6;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.di-swiper-container {
  max-width: 1366px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-button-next.di-swiper-button-next {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 130px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  z-index: 10;
  background-size: unset;
  margin-top: unset;
}

.swiper-button-prev.di-swiper-button-prev {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 130px;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  z-index: 10;
  background-size: unset;
  margin-top: unset;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
